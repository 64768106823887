<template>
  <div class="login_container">
    <div class="FormBox bbox">
      <el-form ref="forgetPwdForm"
               :model="forgetPwdForm"
               :rules="forgetPwdFormRules"
               label-width="0"
               class="login_form">
        <div class="mt30 mb50 flex align-center justify-between">
          <span class="text333 f20 fw700">{{$t('login.lostPwd')}}</span>
          <LangSelect></LangSelect>
        </div>
        <!-- 手机号 -->
        <el-form-item prop="phone">
          <el-input :placeholder="$t('login.phone')"
                    v-model="forgetPwdForm.phone"
                    clearable>

          </el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item prop="authCode">
          <el-input v-model="forgetPwdForm.authCode"
                    :placeholder="$t('reg.authCode')"
                    clearable>
            <span class="pointer textblue positiona handleCode"
                  slot="suffix"
                  @click="handleCodeClick"
                  :disabled="isDisabled">{{ isDisabled ? countText.count + 's后获取' : countText.click }}</span>
          </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="forgetPwdForm.password"
                    type="password"
                    :placeholder="$t('forgetPwd.pwd')"
                    show-password
                    clearable></el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item prop="newPassword">
          <el-input v-model="forgetPwdForm.newPassword"
                    type="password"
                    :placeholder="$t('forgetPwd.newPwd')"
                    show-password
                    clearable></el-input>
        </el-form-item>
        <!-- 确 认按钮 -->
        <el-form-item class="btns mt60">
          <el-button type="primary"
                     @click="login('forgetPwdForm')"
                     class="f16 fw700">{{$t('forgetPwd.confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
export default {
  components: { LangSelect },
  data () {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.forgetPwdForm.newPassword !== '') {
          this.$refs.forgetPwdForm.validateField('newPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.forgetPwdForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      forgetPwdForm: {
        phone: "",
        authCode: "",
        password: "",
        newPassword: "",
      },
      // 表单验证
      forgetPwdFormRules: {
        phone: [{ validator: validatorPhone, trigger: "blur" }],
        authCode: [{ validator: checkCode, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        newPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      //短信验证码倒计时
      countText: {
        count: "59",
        click: "获取验证码"
      },
      isDisabled: false,
      timer: null
    };
  },
  methods: {
    //跳转页面
    jump (path) {
      this.$router.push(path);
    },
    // 倒计时
    countTime () {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.countText.count = TIME_COUNT;
        this.isDisabled = true;
        this.timer = setInterval(() => {
          if (this.countText.count > 0 && this.countText.count <= TIME_COUNT) {
            this.countText.count--;
          } else {
            this.isDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 获取验证码
    handleCodeClick (ev) {
      if (this.forgetPwdForm.phone === "") {
        this.$notify.error({
          title: "提示",
          message: "请输入手机号",
          showClose: true,
        });
      } else if (!/^1\d{10}$/.test(this.forgetPwdForm.phone)) {
        this.$notify.error({
          title: "提示",
          message: "手机号格式号格式不正确",
          showClose: true,
        });
      } else {
        var sendData = {};
        sendData.phone = this.forgetPwdForm.phone;
        sendData.type = "changePwd";
        var that = this;
        this.$http.post("authCode/sendMsg", sendData).then(function (res) {
          that.countTime();
          if (200 == res.data.code) {
            that.$notify.success({
              title: "提示",
              message: "发送成功！",
              showClose: true,
            });
          }
        })
      }
    },
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "member/forgetPwd",
            this.forgetPwdForm
          );
          if (200 == res.code) {
            var member = res.data;
            member.isLogin = true;
            localStorage.setItem("member", JSON.stringify(member));
            localStorage.setItem("memberCode", member.memberCode);
            this.$router.push('/index');
          } else {
            this.$notify.error({
              title: "提示",
              message: "重设密码失败:" + res.message,
              showClose: true,
            });
          }
          this.$router.push("/login");
        } else {
          console.log("error submit!!");
          return false;
        }
      });

    },

  },
};
</script>

<style scoped>
.login_container {
  height: 100vh;
  width: 100%;
}
.FormBox {
  padding: 30px;
}

.btns .el-button {
  display: block;
  width: calc(100vw - 60px);
}
.handleCode {
  top: 9px;
  right: 32px;
  width: 80px;
}
</style>
